export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function getExperienceTime() {
  const today = new Date();
  const startDate = new Date("2013-10-01");
  const timeSinceSatrt = today.getFullYear() - startDate.getFullYear();
  return timeSinceSatrt;
}
